
import React, {useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './hero1.css';
import lig from  '../../asset/limalogo.png';
import twitter from '../../asset/twitter.png';
import etherscan from '../../asset/etherscan.png';
import telegram from '../../asset/telgram.png';

import dextool from '../../asset/dextools.png'
 

const Hero = () => {
    

    const copyText = (text) => {
      navigator.clipboard.writeText(text)
        .then(() => {
          // This is where you handle successful copy
          alert("Copied to clipboard!");
        })
        .catch(err => {
          // This is where you handle any errors
          console.error('Failed to copy: ', err);
        });
    };
   
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    
  return (
    <div id='#home' >
        <div className="container hero__inner-cont">
        
                
                    <div className="  hero__inner-cont__left" data-aos="zoom-out-right" data-aos-duration="1000">
                        <p className='heading'>A Journey to the moon</p>
                        <h1 className='hero-heading'>Welcome to <span>LIGMA</span></h1>
                        <p className="">Heard Fornitenius virgonetis is mutating on Basechain, turning into Ligma. What's ligma?
</p>
                        
                        <div className="buttondiv">

                            <button className="btn hero-btn">
                                <a href="https://t.me/ligmabase" target="_blank" rel="noopener noreferrer">Join Community </a>
                            </button>
                            <button className="btn hero-btn">
                                <a href="https://app.uniswap.org/swap?outputCurrency=0xA63380E500CaCA1c237C56e5b580B350eBB5C4a2&chain=base" target="_blank" rel="noopener noreferrer">Buy Ligma </a>
                            </button>
                        </div>
                        <div className="logo-link">
                             <a href="https://x.com/LigmaBase" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter" /></a>
                            <a href="https://basescan.org/token/0xA63380E500CaCA1c237C56e5b580B350eBB5C4a2" target="_blank" rel="noopener noreferrer"><img src={etherscan} alt="etherscan" /></a>
                             <a href="https://t.me/ligmabase" target="_blank" rel="noopener noreferrer"><img src={telegram} alt="telegra" /></a>
                            
                             <a href="https://www.dextools.io/app/en/base/pair-explorer/0x21d193d33c649825145e8a05934d2b1f86858089?t=1711591298843" target="_blank" rel="noopener noreferrer"><img src={dextool} alt="dextool" /></a>
                        </div>
  </div>
                  
                    <div className="hero-img" data-aos="zoom-out-left" data-aos-duration="1000">
                       
                    <img src= {lig} alt="hero picture"  className="image"/>
                    </div>
                    <h3 className= "ca"onClick={() => copyText('Text to be copied')}>ca:0xA63380E500CaCA1c237C56e5b580B350eBB5C4a2</h3>
    
             
                </div>




             
       
    </div>
  )
}

export default Hero